import axios from 'axios'
import { IdentityReturnUrl } from '../types/IdentityReturnUrl'
import getBaseUrlConfig from '../utils/baseUrlConfigStore'
import { AuthenticationConfiguration } from '../types/AuthenticationConfiguration'
import { CanUserImpersonateResponse } from '../types/CanUserImpersonateResponse'
import { CanUserImpersonate } from '../types/CanUserImpersonate'
import { ContinueImpersonationUrl } from '../types/ContinueImpersonationUrl'
import { ContinueImpersonationResponse } from '../types/ContinueImpersonationUrlResponse'

export const apiClient = axios.create({
  baseURL: getBaseUrlConfig().apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const fetchReturnUrl = async (): Promise<IdentityReturnUrl> => {
  const url = `/get-impersonation-redirect-url`
  const response = await apiClient.post<IdentityReturnUrl>(url)
  return response.data
}

export const fetchConfiguration = async (): Promise<AuthenticationConfiguration> => {
  const url = `/configuration`
  const response = await apiClient.get<AuthenticationConfiguration>(url)
  return response.data
}

export const fetchCanUserImpersonate = async (payload: CanUserImpersonate): Promise<CanUserImpersonateResponse> => {
  const url = `/get-can-user-impersonate`
  const response = await apiClient.post<CanUserImpersonateResponse>(url, payload)
  return response.data
}

export const fetchContinueImpersonationUrl = async (
  payload: ContinueImpersonationUrl,
): Promise<ContinueImpersonationResponse> => {
  const url = `/get-continue-impersonation-url`
  const response = await apiClient.post<ContinueImpersonationResponse>(url, payload)
  return response.data
}

export const getImpersonationToken = async (code: string, codeVerifier: string) => {
  const url = `/get-impersonation-token`
  const response = await apiClient.post<object>(url, {
    code,
    codeVerifier,
  })
  return response.data
}
