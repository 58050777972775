export type StateCache = {
  codeVerifier: string
  redirectUri: string | null
}

export type ImpersonationState = {
  state: string
  codeVerifier: string
  redirectUri: string | null
}

export const storeCache = (state: ImpersonationState): void => {
  const cache: StateCache = {
    codeVerifier: state.codeVerifier,
    redirectUri: state.redirectUri,
  }
  sessionStorage.setItem(state.state, JSON.stringify(cache))
}

export const getCache = (state: string | null): ImpersonationState | null => {
  if (state === null) return null

  const cache = sessionStorage.getItem(state)
  if (cache === null) return null

  const obj = JSON.parse(cache) as StateCache
  return {
    state,
    codeVerifier: obj.codeVerifier,
    redirectUri: obj.redirectUri,
  }
}

export const clearCache = (state: string): void => {
  sessionStorage.removeItem(state)
}
