import { ReactChild, ReactChildren } from 'react'
import StyledButton from './Button.styles'

type ButtonProps = {
  children: ReactChild | ReactChildren
  type: 'submit' | 'reset' | 'button' | undefined
  id: string
}

const Button = ({ children, id, type }: ButtonProps) => {
  return (
    <StyledButton id={id} type={type}>
      {children}
    </StyledButton>
  )
}

export default Button
