import { useEffect } from 'react'
import { getImpersonationToken } from '../../api/apiClient'
import useQuery from '../../customHooks/useQuery'
import getBaseUrlConfig from '../../utils/baseUrlConfigStore'
import { clearCache, getCache, ImpersonationState } from './impersonationCache'
import { Spinner } from '@marketfinance/ui-framework-public'

const handle = async (code: string, impersonationState: ImpersonationState) => {
  await getImpersonationToken(code, impersonationState.codeVerifier)
  clearCache(impersonationState.state)
  const environmentUrls = getBaseUrlConfig()
  const target = impersonationState.redirectUri ?? environmentUrls.miMarketWeb
  window.location.replace(target)
}

const ImpersonateCallback = () => {
  const query = useQuery()
  useEffect(() => {
    const state = query.get('state')
    const code = query.get('code')
    const impersonationState = getCache(state)
    if (code === null || impersonationState === null) return
    void handle(code, impersonationState)
  }, [query])
  return <Spinner text="Loading..." />
}

export default ImpersonateCallback
