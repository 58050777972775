import styled from 'styled-components'

const Container = styled.div`
  max-width: 330px;
  min-width: 0;
  margin: auto;
  padding: 15px;
`

const Header = styled.h1`
  margin-top: 21px;
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.1;
  color: #222;
`

const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  margin-top: 0.5rem;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  height: 44px;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`

const Label = styled.label`
  display: inline-block;
  font-family: 'Open Sans', helvetica, arial, sans-serif;
  font-size: 15px;
  color: #464c4c;
`

const InputError = styled.div`
  margin-top: 0.5rem;
  color: #ff3333;
  font-size: small;
`

export { Container, Header, Label, Input, InputError }
