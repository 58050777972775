import { ReactChild, ReactChildren } from 'react'
import StyledErrorMessage from './ErrorMessage.styles'

type ErrorMessageProps = {
  children?: ReactChild | ReactChildren
  id: string
}
const ErrorMessage = ({ children, id }: ErrorMessageProps) => {
  return (
    <StyledErrorMessage>
      <h2 id={id || 'error-message'}>{children || 'Oops... something went wrong'}</h2>
    </StyledErrorMessage>
  )
}

export default ErrorMessage
