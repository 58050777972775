import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Navigation, Theme } from '@marketfinance/ui-framework-public'
import Impersonation from './containers/Impersonation/Impersonation'
import IdentityRedirection from './containers/IdentityRedirection/IdentityRedirection'
import ImpersonateCallback from './containers/IdentityRedirection/ImpersonateCallback'

function App() {
  return (
    <Theme>
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route path="/redirect" element={<IdentityRedirection />} />
          <Route path="/impersonation" element={<Impersonation />} />
          <Route path="/impersonate-callback" element={<ImpersonateCallback />} />
        </Routes>
      </BrowserRouter>
    </Theme>
  )
}

export default App
