import useQuery from '../../customHooks/useQuery'
import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Container, Header, Input, InputError, Label } from './Impersonation.styles'
import Button from '../../components/Button/Button'
import { fetchCanUserImpersonate, fetchContinueImpersonationUrl } from '../../api/apiClient'
import { CanUserImpersonate } from '../../types/CanUserImpersonate'
import { ContinueImpersonationUrl } from '../../types/ContinueImpersonationUrl'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import { Spinner } from '@marketfinance/ui-framework-public'
import { AxiosError } from 'axios'

enum ImpersonationStatus {
  Initial,
  Callback,
  NoUser,
  Done,
  Authorised,
  Unauthorised,
  Error,
}

const isAxiosError = <T extends object | string>(a: any): a is AxiosError<T> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return a.isAxiosError === true
}

type ImpersonationContext = {
  sessionToken: string
  flowState: string
}

type ImpersonationState = {
  status: ImpersonationStatus
  context?: ImpersonationContext
  error?: string
}

const userNotAllowedMessage = 'The user is not allowed to impersonate'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleInitial = (setImpersonationState: Dispatch<SetStateAction<ImpersonationState>>) => {
  // Will implement if needed
}

const handleCallback = async (
  state: ImpersonationState,
  setImpersonationState: Dispatch<SetStateAction<ImpersonationState>>,
) => {
  if (state.context === undefined) {
    setImpersonationState({
      status: ImpersonationStatus.Error,
      error: 'Invalid state',
    })
    return
  }

  const payload: CanUserImpersonate = {
    sessionToken: state.context.sessionToken,
  }

  try {
    const result = await fetchCanUserImpersonate(payload)
    setImpersonationState({
      status: result.isAllowed ? ImpersonationStatus.Authorised : ImpersonationStatus.Unauthorised,
      context: state.context,
    })
  } catch (error) {
    if (isAxiosError<string>(error)) {
      const responseStatus = error.response?.status
      if (responseStatus === 500) {
        setImpersonationState({
          status: ImpersonationStatus.Error,
          error: "It looks like we're having a problem, please try again shortly",
        })
      } else {
        const status = responseStatus === 403 ? ImpersonationStatus.Unauthorised : ImpersonationStatus.Initial
        setImpersonationState({
          status,
        })
      }
    } else {
      setImpersonationState({
        status: ImpersonationStatus.Error,
        error: (error as Error).message,
      })
    }
  }
}

const handleDone = async (
  state: ImpersonationState,
  email: string,
  setImpersonationState: Dispatch<SetStateAction<ImpersonationState>>,
) => {
  if (state.context === undefined || email.length === 0) {
    setImpersonationState({
      status: ImpersonationStatus.Error,
      error: 'Invalid state',
    })
    return
  }

  const { sessionToken, flowState } = state.context
  const continueImpersonationPayload: ContinueImpersonationUrl = {
    sessionToken,
    state: flowState,
    email,
  }

  try {
    const response = await fetchContinueImpersonationUrl(continueImpersonationPayload)
    if (!response.redirectUrl.startsWith('http')) {
      setImpersonationState({
        status: ImpersonationStatus.Error,
        error: 'Unknown redirect URI',
      })
    } else {
      localStorage.setItem(flowState, 'true')
      window.location.replace(response.redirectUrl)
    }
  } catch (error) {
    if (isAxiosError<string>(error) && error.response?.status === 404) {
      setImpersonationState({
        status: ImpersonationStatus.Authorised,
        context: state.context,
        error: error.response.data,
      })
    } else {
      setImpersonationState({
        status: ImpersonationStatus.Error,
        error: (error as Error).message,
      })
    }
  }
}

const Impersonation = () => {
  const query = useQuery()
  const [impersonateEmail, setImpersonateEmail] = useState<string>('')
  const [impersonationState, setImpersonationState] = useState<ImpersonationState>(() => {
    const sessionToken = query.get('session_token')
    const flowState = query.get('state')

    if (sessionToken !== null && flowState !== null && localStorage.getItem(flowState) === null) {
      return {
        status: ImpersonationStatus.Callback,
        context: {
          flowState,
          sessionToken,
        },
      }
    }

    return {
      status: ImpersonationStatus.Initial,
    }
  })

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setImpersonationState({
        status: ImpersonationStatus.Done,
        context: impersonationState.context,
      })
    },
    [impersonationState.context],
  )

  useEffect(() => {
    switch (impersonationState.status) {
      case ImpersonationStatus.Initial:
      case ImpersonationStatus.NoUser: {
        const target = new URL('/redirect', window.location.origin)
        target.search = window.location.search
        window.location.assign(target)
        break
      }
      case ImpersonationStatus.Callback:
        void handleCallback(impersonationState, setImpersonationState)
        break
      case ImpersonationStatus.Done:
        void handleDone(impersonationState, impersonateEmail, setImpersonationState)
        break
    }
  }, [impersonationState.status, impersonationState.context, impersonateEmail, impersonationState])

  switch (impersonationState.status) {
    case ImpersonationStatus.Error:
      return <ErrorMessage id="error-message">{impersonationState.error}</ErrorMessage>
    case ImpersonationStatus.Unauthorised:
      return <ErrorMessage id="impersonation-error">{userNotAllowedMessage}</ErrorMessage>
    case ImpersonationStatus.Authorised:
      return (
        <Container>
          <form onSubmit={handleSubmit} id="impersonation-form">
            <Header id="impersonate-header">Impersonate user</Header>
            <Label htmlFor="impersonation-email">User to impersonate *</Label>
            <Input
              id="impersonation-email"
              type="email"
              required
              name="impersonation-email"
              aria-label="impersonation-email-input"
              value={impersonateEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImpersonateEmail(e.target.value)}
            />
            {impersonationState.error && <InputError id="input-error">{impersonationState.error}</InputError>}
            <Button id="submit-button" type={'submit'}>
              Log in as the user
            </Button>
          </form>
        </Container>
      )
    case ImpersonationStatus.Initial:
    default:
      return <Spinner text="Loading..." />
  }
}

export default Impersonation
