import { useEffect } from 'react'
import { Spinner } from '@marketfinance/ui-framework-public'
import getBaseUrlConfig from '../../utils/baseUrlConfigStore'

const IdentityRedirection = () => {
  useEffect(() => {
    const urlConfig = getBaseUrlConfig()
    const impersonationUrl = new URL('/admin/impersonate', urlConfig.miMarketWeb)
    window.location.replace(impersonationUrl)
  }, [])

  return <Spinner text="Loading..." />
}

export default IdentityRedirection
