import styled from 'styled-components'

const StyledButton = styled.button`
  color: #fff;
  background-color: #61bb9b;
  border-color: #61bb9b;
  min-width: 146px;
  padding: 12px 12px 10px;
  font-size: 14px;
  line-height: 1.42857;
  user-select: none;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #46a382;
    border-color: #439c7c;
  }
`

export default StyledButton
