interface IUrlPathCollection {
  authBaseUrl: string
  apiBaseUrl: string
  miMarketWeb: string
}

interface IUrlMapping {
  [Key: string]: IUrlPathCollection
}

const urlMapping: IUrlMapping = {
  'identity-center.kriya.dev': {
    authBaseUrl: 'https://portal.kriya.dev',
    apiBaseUrl: 'https://identity-center-api.kriya.dev/api',
    miMarketWeb: 'https://portal.kriya.dev',
  },
  'identity-center-local.kriya.dev': {
    authBaseUrl: 'https://public-local.kriya.dev',
    apiBaseUrl: 'http://identity-center-local.kriya.dev:7074/api',
    miMarketWeb: 'https://public-local.kriya.dev',
  },
  'identity-center.kriya.co': {
    authBaseUrl: 'https://portal.kriya.co',
    apiBaseUrl: 'https://identity-center-api.kriya.co/api',
    miMarketWeb: 'https://portal.kriya.co',
  },
  localhost: {
    authBaseUrl: 'https://public-local.kriya.dev',
    apiBaseUrl: 'http://identity-center-local.kriya.dev:7074/api',
    miMarketWeb: 'https://public-local.kriya.dev',
  },
}

const getBaseUrlConfig = (): IUrlPathCollection => {
  const deploymentHost = new URL(window.location.href).hostname
  return urlMapping[deploymentHost]
}

export default getBaseUrlConfig
